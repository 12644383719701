import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { postEmailToken } from '../redux/actions/tokenActions';
import SubmitEmailForm from '../components/forms/SubmitEmailForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import { translateArray } from '../common/Util';
import { MessageType } from '../common/Constants';
import { getProfile } from '../common/SessionManager';
import { getChangeEmailErrorMessage } from '../common/MessageUtil';

export class ChangeMyEmail extends Component {
  constructor(props) {
    super(props);
    this.successMessage = 'Your_request_has_been_processed._In_order_to_maintain_the_security_of_the_account_you_will_receive_an_email_containing_a_verification_code_and_a_link_that_will_allow_you_to_update_your_email_address._Follow_the_instructions_in_the_email_in_order_to_update_your_account_to_the_new_email_address.';
    this.profile = getProfile();
  }

  componentDidMount() {
    document.title = `Tableau - ${this.props.t('Change_My_Email')}`;
  }

  render() {
    const {
      t, changeEmail, success, changeEmailError, isFetching, i18n, changeEmailRequestId,
    } = this.props;

    const onSubmit = (formData) => {
      changeEmail(this.profile.uid, formData.email, i18n.language);
    };
    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {changeEmailError && (
            <Message
              id="errorMessage"
              text={translateArray(getChangeEmailErrorMessage(changeEmailError))}
              messageType={MessageType.error}
            />
          )}
          {success && <Message id="successMessage" text={t(this.successMessage)} messageType={MessageType.notice} />}
          {!success && (
            <SubmitEmailForm
              id="newEmailForm"
              title={t('Change_Email')}
              subTitle={`${t('Name')}: ${this.profile.displayName}`}
              additionalInfo={`${t('Email')}: ${this.profile.email}`}
              buttonText={t('Change_Email')}
              buttonId="changeEmailButton"
              inputTitle={t('New_Email')}
              onSubmit={onSubmit}
              isFetching={isFetching}
              noValidate
            />
          )}
        </div>
        <input type="hidden" id="changeEmailRequestId" value={changeEmailRequestId} />
      </section>
    );
  }
}
ChangeMyEmail.propTypes = {
  t: PropTypes.func.isRequired,
  success: PropTypes.bool,
  changeEmailError: PropTypes.object,
  isFetching: PropTypes.bool,
  changeEmail: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  changeEmailRequestId: PropTypes.string,
};

ChangeMyEmail.defaultProps = {
  changeEmailError: null,
  success: false,
  isFetching: false,
  changeEmailRequestId: '',
};

export const mapStateToProps = state => ({
  success: state.changeEmailReducer.success,
  changeEmailError: state.changeEmailReducer.error,
  isFetching: state.changeEmailReducer.isFetching,
  changeEmailRequestId: state.changeEmailReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  changeEmail: bindActionCreators(postEmailToken, dispatch),
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(ChangeMyEmail));
