import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { MessageType, TokenTypes } from '../common/Constants';
import { sendAdminInstructions } from '../common/Util';
import { HELP_ROUTE } from '../routes';
import Message from '../components/Message';
import ResendToken from './ResendToken';

const Unauthorized = (props) => {
  const { t, message, email } = props;
  document.title = t('Tableau - Account Management');
  if (message === 'unverified') {
    return (
      <ResendToken
        externalError={t('Your_account_is_pending_activation._Enter_your_email_to_recreate_your_activation_email.')}
        tokenType={TokenTypes.RESEND_ACTIVATION}
      />
    );
  }
  if (message === 'deactivated') {
    return (
      <section className="section min-height">
        <div className="content-container" id="errorMessage">
          <Message
            id="unauthorized"
            text={t(
              'Your_account_has_been_deactivated._Please_contact_our_a_href_link_Customer_Service_team_a_for_help.',
              { link: HELP_ROUTE },
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }
  if (message === 'admin') {
    sendAdminInstructions(email);
    return (
      <section className="section min-height">
        <div className="content-container" id="errorMessage">
          <Message
            id="admin_blocked"
            text={t(
              'For security reasons your login attempt has been blocked. Please check your email for instructions on how to access your account.',
            // to do
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }

  if (message === 'sfOrgNotVerified') {
    return (
      <section className="section min-height">
        <div className="content-container" id="errorMessage">
          <Message
            id="unauthorized"
            text={t(
              'You_have_not_linked_your_salesforce_account_to_Tableau._Please_check_your_email_for_instructions_on_how_to_link_your_account.',
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }

  if (message === 'restricted') {
    return (
      <section className="section min-height">
        <div className="content-container" id="errorMessage">
          <Message
            id="unauthorized"
            text={t(
              'Accounts_with_email_addresses_from_restricted_regions_are_not_allowed._For_more_information,_see_Salesforce',
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }

  return (
    <section className="section min-height">
      <div className="content-container" id="errorMessage">
        <Message
          id="unauthorized"
          text={t("Were_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL.")}
          messageType={MessageType.error}
        />
      </div>
    </section>
  );
};

Unauthorized.propTypes = {
  t: PropTypes.func.isRequired,
  message: PropTypes.string,
  email: PropTypes.string,
};

Unauthorized.defaultProps = {
  message: null,
  email: null,
};

export default translate()(Unauthorized);
