/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:19:24
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:31:13
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import { ACTIVATE_USER_ROUTE, CHANGEEMAIL_ROUTE, RESETPASSWORD_ROUTE, SEARCH_ROUTE, UNBLOCK_USER_ROUTE } from '../../routes';
import { getProfile } from '../../common/SessionManager';
import NavItem from './NavItem';

class Navigation extends Component {
  render() {
    const { t } = this.props;
    const profile = getProfile();
    if (profile && (profile.profileAdmin || profile.profileReader)) {
      return (
        <div className="subnav sticky--desktop">
          <div className="content-container">
            <h5 className="subnav__title">{profile.displayName}</h5>

            <div className="subnav__links sticky--mobile sticky--tablet">
              <div className="subnav__links-wrapper">
                <ul className="link-list link-list--inline ">
                  <NavItem to={SEARCH_ROUTE}>{t('Account_Search')}</NavItem>
                  {profile.profileAdmin && <NavItem to={CHANGEEMAIL_ROUTE}>{t('Change_Email')}</NavItem>}
                  <NavItem to={RESETPASSWORD_ROUTE}>{t('Reset_Password')}</NavItem>
                  {profile.profileAdmin && <NavItem to={ACTIVATE_USER_ROUTE}>{t('Activate_User')}</NavItem>}
                  {profile.profileAdmin && <NavItem to={UNBLOCK_USER_ROUTE}>{t('Unblock_User')}</NavItem>}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

Navigation.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(Navigation);
