/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:23:23
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-03-12 13:19:47
 */

import { UserGroups, Language } from './Constants';

export const industry = [
  { id: '1', value: '', label: 'Industry_Optional' },
  { id: '2', value: 'Academic', label: 'Academic' },
  { id: '3', value: 'Aerospace & Defense', label: 'Aerospace_&_Defense' },
  { id: '4', value: 'Agriculture & Mining', label: 'Agriculture_&_Mining' },
  { id: '5', value: 'Associations & Non-profits', label: 'Associations_&_Non-profits' },
  { id: '6', value: 'Automotive', label: 'Automotive' },
  { id: '7', value: 'Banking', label: 'Banking' },
  { id: '8', value: 'Bio Tech', label: 'Bio_Tech' },
  { id: '9', value: 'Business Services', label: 'Business_Services' },
  { id: '10', value: 'Chemicals', label: 'Chemicals' },
  { id: '11', value: 'Communications', label: 'Communications' },
  { id: '12', value: 'Communications, Media & Technology', label: 'Communications_Media_&_Technology' },
  { id: '13', value: 'Construction', label: 'Construction' },
  { id: '14', value: 'Consumer Goods', label: 'Consumer_Goods' },
  { id: '15', value: 'Consumer Services', label: 'Consumer_Services' },
  { id: '16', value: 'Education', label: 'Education' },
  { id: '17', value: 'Energy & Resources', label: 'Energy_&_Resources' },
  { id: '18', value: 'Fed DoD', label: 'Fed_DoD' },
  { id: '19', value: 'Fed Civilian', label: 'Fed_Civilian' },
  { id: '20', value: 'Financial Services', label: 'Financial_Services' },
  { id: '21', value: 'Government', label: 'Government' },
  { id: '22', value: 'Healthcare Payers', label: 'Healthcare_Payers' },
  { id: '23', value: 'Healthcare Providers', label: 'Healthcare_Providers' },
  { id: '24', value: 'High Technology', label: 'High_Technology' },
  { id: '25', value: 'Industrial Machinery', label: 'Industrial_Machinery' },
  { id: '26', value: 'Insurance', label: 'Insurance' },
  { id: '27', value: 'Life Sciences', label: 'Life_Sciences' },
  { id: '28', value: 'Media & Entertainment', label: 'Media_&_Entertainment' },
  { id: '29', value: 'Medical Devices', label: 'Medical_Devices' },
  { id: '30', value: 'Mill Products', label: 'Mill_Products' },
  { id: '31', value: 'Natural Resources', label: 'Natural_Resources' },
  { id: '32', value: 'Oil & Gas', label: 'Oil_&_Gas' },
  { id: '33', value: 'Partner', label: 'Partner' },
  { id: '34', value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { id: '35', value: 'Public Sector', label: 'Public_Sector' },
  { id: '36', value: 'Retail & Consumer Goods', label: 'Retail_&_Consumer_Goods' },
  { id: '37', value: 'Retail & Wholesale', label: 'Retail_&_Wholesale' },
  { id: '38', value: 'Securities & Investments', label: 'Securities_&_Investments' },
  { id: '39', value: 'Services', label: 'Services' },
  { id: '40', value: 'Transportation & Logistics', label: 'Transportation_&_Logistics' },
  { id: '41', value: 'Travel & Hospitality', label: 'Travel_&_Hospitality' },
  { id: '42', value: 'Travel & Transportation', label: 'Travel_&_Transportation' },
  { id: '43', value: 'Utilities', label: 'Utilities' },
];

export const department = [
  { id: '1', value: '', label: 'Department_Optional' },
  { id: '2', value: 'Administration (Management)', label: 'Administration_Management' },
  { id: '3', value: 'Analytics', label: 'Analytics' },
  { id: '4', value: 'Channel Partner', label: 'Channel_Partner' },
  { id: '5', value: 'Engineering / R&D', label: 'Engineering_R&D' },
  { id: '6', value: 'Finance', label: 'Finance' },
  { id: '7', value: 'Human Resources', label: 'Human_Resources' },
  { id: '8', value: 'IT', label: 'IT' },
  { id: '9', value: 'Legal', label: 'Legal' },
  { id: '10', value: 'Manufacturing', label: 'Manufacturing' },
  { id: '11', value: 'Marketing', label: 'Marketing' },
  { id: '12', value: 'Product Management', label: 'Product_Management' },
  { id: '13', value: 'Purchasing', label: 'Purchasing' },
  { id: '14', value: 'Sales', label: 'Sales' },
  { id: '15', value: 'Supply Chain & Distribution', label: 'Supply_Chain_&_Distribution' },
  { id: '16', value: 'Support / Service', label: 'Support_Service' },
  { id: '17', value: 'Other', label: 'Other' },
];

export const jobrole = [
  { id: '1', value: '', label: 'Job_Role_Optional' },
  { id: '2', value: 'Analyst', label: 'Analyst' },
  { id: '3', value: 'Architect', label: 'Architect' },
  { id: '4', value: 'C-Level', label: 'C-Level' },
  { id: '5', value: 'CEO/President', label: 'CEO_President' },
  { id: '6', value: 'Chancellor/Dean/Provost', label: 'ChancellorDeanProvost' },
  { id: '7', value: 'Consultant/System Integrator', label: 'ConsultantSystem_Integrator' },
  { id: '8', value: 'Coordinator/Specialist', label: 'CoordinatorSpecialist' },
  { id: '9', value: 'Developer/Engineer', label: 'DeveloperEngineer' },
  { id: '10', value: 'Director', label: 'Director' },
  { id: '11', value: 'Manager', label: 'Manager' },
  { id: '12', value: 'Professor/Teacher', label: 'Professor_Teacher' },
  { id: '13', value: 'Student', label: 'Student' },
  { id: '14', value: 'VP', label: 'VP' },
  { id: '15', value: 'Other', label: 'Other' },
];

export const UserSearchGroups = [
  { id: '1', value: '', label: 'Group' },
  { id: '2', value: UserGroups.profileReaders, label: 'Profile_Read-Only' },
  { id: '3', value: UserGroups.profileAdmins, label: 'Profile_Admin' },
  {
    id: '4',
    value: UserGroups.emailViewers,
    label: 'Profile_Admin_with_Email',
  },
  {
    id: '5',
    value: UserGroups.permissionGranters,
    label: 'Profile_Admin_with_Permission_Grant',
  },
  {
    id: '6',
    value: UserGroups.tableauOnlineServerAdmins,
    label: 'Tableau Online Server Admin',
  },
];

export const GroupMembership = [
  { id: '1', value: UserGroups.customers, label: 'Customer_Portal' },
  { id: '2', value: UserGroups.partners, label: 'Partner_Portal' },
  { id: '3', value: UserGroups.partnerRessources, label: 'Partner_Resource' },
  { id: '4', value: UserGroups.profileReaders, label: 'Profile_Read-Only' },
  { id: '5', value: UserGroups.profileAdmins, label: 'Profile_Admin' },
  { id: '6', value: UserGroups.tableauOnlineServerAdmins, label: 'Tableau Online Server Admin' },
  { id: '7', value: '', label: 'None' },
];

// Since these are checkboxes and seperate from the other, lets split them out.
export const Permissions = [
  { id: '1', value: UserGroups.emailViewers, label: 'With_Email' },
  {
    id: '2',
    value: UserGroups.permissionGranters,
    label: 'With_Permission_Gran',
  },
];

export const LanguageData = [
  { id: '1', value: '', label: 'Preferred_Language_Optional' },
  { id: '2', value: Language.english.code, label: Language.english.label },
  { id: '3', value: Language.french.code, label: Language.french.label },
  { id: '4', value: Language.deutch.code, label: Language.deutch.label },
  { id: '5', value: Language.japanese.code, label: Language.japanese.label },
  { id: '6', value: Language.chinese.code, label: Language.chinese.label },
  { id: '7', value: Language.korean.code, label: Language.korean.label },
  {
    id: '8',
    value: Language.portuguese.code,
    label: Language.portuguese.label,
  },
  { id: '9', value: Language.spanish.code, label: Language.spanish.label },
  { id: '10', value: Language.italian.code, label: Language.italian.label },
  { id: '11', value: Language.dutch.code, label: Language.dutch.label },
  { id: '12', value: Language.english_uk.code, label: Language.english_uk.label },
  { id: '13', value: Language.french_canada.code, label: Language.french_canada.label },
  { id: '14', value: Language.swedish.code, label: Language.swedish.label },
  { id: '15', value: Language.thai.code, label: Language.thai.label },
  { id: '16', value: Language.chinese_taiwanese.code, label: Language.chinese_taiwanese.label },
];
