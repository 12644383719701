import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getProfile } from '../../common/SessionManager';
import ValidationInput from './elements/ValidationInput';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };
    this.title = 'Change_Password';
    this.currentPasswordTitle = 'Current_Password';
    this.passwordTitle = 'New_Password';
    this.confirmPasswordTitle = 'Confirm_New_Password';
    this.buttonText = 'Change_Password';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    oldPassword: inputs.currentPassword,
    newPassword: inputs.password,
  });

  render() {
    const { t } = this.props;

    return (
      <div>
        <Formsy
          className="grid--5 form--full-width"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <h1 className="title">{t(this.title)}</h1>
          {getProfile() && getProfile().tabOnlineServerGroupAdmin ? (
            <p>
              {t(
                'Passwords_are_case_sensitive_16-100_characters_long._Requires_at_least_one_number_0-9_one_uppercase_letter_one_lowercase_letter_and_one_of_the_following_characters:_$_&_-_._:___._You_cannot_use_previously_used_passwords.',
              )}
            </p>
          ) : (
            <p>
              {t(
                'Passwords_are_case_sensitive_16-100_characters_long._Requires_at_least_one_number_0-9_one_uppercase_letter_one_lowercase_letter_and_one_of_the_following_characters:_$_&_-_._:___._You_cannot_use_previously_used_passwords.',
              )}
            </p>
          )}

          <ValidationInput
            id="currentPassword"
            maxLength="100"
            name="currentPassword"
            placeholder={t(this.currentPasswordTitle)}
            type="password"
            required
            value=""
            validationErrors={{
              isDefaultRequiredValue: t('This_field_is_required.'),
            }}
            title={t(this.currentPasswordTitle)}
          />
          <ValidationInput
            id="password"
            maxLength="100"
            name="password"
            placeholder={t(this.passwordTitle)}
            type="password"
            required
            value=""
            validations="validPassword"
            validationErrors={{
              isDefaultRequiredValue: t('This_field_is_required.'),
            }}
            validationError={t(
              'Passwords_must_contain_at_least_one_number_one_uppercase_letter_one_lowercase_letter_one_special_character_and_be_at_least_8_characters_long.',
            )}
            title={t(this.passwordTitle)}
          />
          <ValidationInput
            id="confirmPassword"
            maxLength="100"
            name="confirmPassword"
            placeholder={t(this.confirmPasswordTitle)}
            type="password"
            required
            value=""
            validations="equalsField:password"
            validationErrors={{
              isDefaultRequiredValue: t('This_field_is_required.'),
            }}
            validationError={t('This_must_match_the_Password_field_above.')}
            title={t(this.confirmPasswordTitle)}
          />
          <div className="form-buttons">
            <button
              type="submit"
              className="cta cta--secondary"
              id="changePasswordButton"
              disabled={this.state.submitDisabled}
            >
              {t(this.buttonText)}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
export default translate()(ChangePasswordForm);
