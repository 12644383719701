import { Language } from '../../../common/Constants';
import deConfig from './de-DE.json';
import enConfig from './en-US.json';
import enUKConfig from './en-GB.json';
import frConfig from './fr-FR.json';
import frCaConfig from './fr-CA.json';
import jaConfig from './ja-JP.json';
import zhConfig from './zh-CN.json';
import zhTWConfig from './zh-TW.json';
import koConfig from './ko-KR.json';
import ptConfig from './pt-BR.json';
import esConfig from './es-ES.json';
import itConfig from './it-IT.json';
import nlConfig from './nl-NL.json';
import svConfig from './sv-SE.json';
import thConfig from './th-TH.json';

const getAddressConfig = (language) => {
  let result = enConfig;
  switch (language.toUpperCase()) {
    case Language.deutch.code.toUpperCase():
      result = deConfig;
      break;
    case Language.english.code.toUpperCase():
      result = enConfig;
      break;
    case Language.english_uk.code.toUpperCase():
      result = enUKConfig;
      break;  
    case Language.french.code.toUpperCase():
      result = frConfig;
      break;
    case Language.french_canada.code.toUpperCase():
      result = frCaConfig;
      break;
    case Language.japanese.code.toUpperCase():
      result = jaConfig;
      break;
    case Language.chinese.code.toUpperCase():
      result = zhConfig;
      break;
    case Language.chinese_taiwanese.code.toUpperCase():
      result = zhTWConfig;
      break;
    case Language.swedish.code.toUpperCase():
      result = svConfig;
      break;
    case Language.thai.code.toUpperCase():
      result = thConfig;
      break;  
    case Language.korean.code.toUpperCase():
      result = koConfig;
      break;
    case Language.portuguese.code.toUpperCase():
      result = ptConfig;
      break;
    case Language.spanish.code.toUpperCase():
      result = esConfig;
      break;
    case Language.italian.code.toUpperCase():
      result = itConfig;
      break;
    case Language.dutch.code.toUpperCase():
      result = nlConfig;
      break;
    default:
      break;
  }
  if (!result || Object.keys(result).length === 0) {
    result = enConfig;
  }
  return result;
};

export default getAddressConfig;
