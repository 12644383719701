/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:39
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:31:21
 */
import React, { Component } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { Language } from "../common/Constants";

class QuickLinks extends Component {
  render() {
    const {
      t,
      i18n: { language }
    } = this.props;
    let selectedLangInline = "en-us";
    const communityLink = "//community.tableau.com/s/login/?startURL=/s/";
    const tableauPublicHomePageLink = "https://public.tableau.com/login";
    let lowercase_language = language.toLowerCase();
    if (lowercase_language.includes("ja")) {
      selectedLangInline = Language.japanese.code.toLowerCase();
    } else if (lowercase_language.includes("en-gb")) {
      selectedLangInline = Language.english_uk.code.toLowerCase();
    } else if (lowercase_language.includes("fr-fr")) {
      selectedLangInline = Language.french.code.toLowerCase();
    } else if (lowercase_language.includes("fr-ca")) {
      selectedLangInline = Language.french_canada.code.toLowerCase();
    } else if (lowercase_language.includes("de")) {
      selectedLangInline = Language.deutch.code.toLowerCase();
    } else if (lowercase_language.includes("zh-cn")) {
      selectedLangInline = Language.chinese.code.toLowerCase();
    } else if (lowercase_language.includes("zh-tw")) {
      selectedLangInline = Language.chinese_taiwanese.code.toLowerCase();
    } else if (lowercase_language.includes("sv")) {
      selectedLangInline = Language.swedish.code.toLowerCase();
    } else if (lowercase_language.includes("th")) {
      selectedLangInline = Language.thai.code.toLowerCase();
    } else if (lowercase_language.includes("ko")) {
      selectedLangInline = Language.korean.code.toLowerCase();
    } else if (lowercase_language.includes("pt")) {
      selectedLangInline = Language.portuguese.code.toLowerCase();
    } else if (lowercase_language.includes("es")) {
      selectedLangInline = Language.spanish.code.toLowerCase();
    } else if (lowercase_language.includes("it")) {
      selectedLangInline = Language.italian.code.toLowerCase();
    } else if (lowercase_language.includes("nl")) {
      selectedLangInline = Language.dutch.code.toLowerCase();
    }
    return (
      <div className="clearfix">
        <h3>{t("Quick_Links")}</h3>
        <ul>
          <li>
            <a href={`//www.tableau.com/${selectedLangInline}/`}>
              {t("Tableau_Home_Page")}
            </a>
          </li>
          <li>
            <a href={communityLink}>{t("Tableau_Community")}</a>
          </li>
          <li>
            <a
              href={`//www.tableau.com/${selectedLangInline}/blueprint-assessment`}
            >
              {t("Blueprint_Assessment")}
            </a>
          </li>
          <li>
            <a href={tableauPublicHomePageLink}>{t("Tableau_Public")}</a>
          </li>
        </ul>
      </div>
    );
  }
}
QuickLinks.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
};
export default translate()(QuickLinks);
